import React, { Component } from 'react';
import './../custom.css';
import 'animate.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <>
                <div className="container">
                    <div className="center">
                        <p className="animate__animated animate__headShake animate__repeat-3 animate__delay-3s">EZLAB</p>
                    </div>

                </div>
                <div className="terminal">
                    <div className="line">
                        <span id="path">c:/&nbsp;>&nbsp;</span>
                        <input type="text" id="input" />
                    </div>
                </div>
            </>
        );
    }
}
