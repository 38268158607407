import React, {Component} from 'react';
import './../custom.css';

export class NotFound extends Component {
    render() {
        return (
            <div className="container">
                <div className="center">
                    <p>OOPS</p>
                </div>
            </div>
        );
    }
}
