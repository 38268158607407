import React, {Component} from 'react';
import {Route, Routes} from 'react-router-dom';
import './custom.css';
import {NotFound} from "./components/NotFound";
import {Home} from "./components/Home";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        );
    }
}
